const categories = {
  "Beverages": {
    "Types of Coffee Drinks": [
      "Espresso",
      "Latte",
      "Cappuccino",
      "Americano",
      "Mocha",
      "Macchiato",
      "Flat White",
      "Cold Brew",
      "Affogato",
      "Café au Lait",
    ],
    "Brands of Whiskey": [
      "Jack Daniel's",
      "Jameson",
      "Johnnie Walker",
      "Glenfiddich",
      "Macallan",
      "Wild Turkey",
      "Chivas Regal",
      "Maker's Mark",
      "Bulleit Bourbon",
      "Woodford Reserve",
    ],
    "Types of Tequila": [
      "Blanco",
      "Reposado",
      "Añejo",
      "Extra Añejo",
      "Joven",
      "Cristalino",
      "Mixto",
      "Plata",
      "Gold",
      "Silver",
    ],
    "Liquors": [
      "Vodka",
      "Rum",
      "Gin",
      "Brandy",
      "Cognac",
      "Bourbon",
      "Scotch",
      "Absinthe",
      "Schnapps",
      "Triple Sec",
    ],
    "Ice Cream Brands": [
      "Ben & Jerry's",
      "Haagen-Dazs",
      "Breyers",
      "Blue Bell",
      "Tillamook",
      "Talenti",
      "Cold Stone Creamery",
      "Dreyer's",
      "Baskin-Robbins",
      "Magnum",
    ],
    "Beer Brands": [
      "Budweiser",
      "Heineken",
      "Corona",
      "Guinness",
      "Stella Artois",
      "Miller Lite",
      "Coors Light",
      "Samuel Adams",
      "Blue Moon",
      "Dos Equis",
    ],
    "Pop-Tart Flavors": [
      "Strawberry",
      "Brown Sugar Cinnamon",
      "Blueberry",
      "S'mores",
      "Cookies & Creme",
      "Cherry",
      "Wild Berry",
      "Chocolate Fudge",
      "Hot Fudge Sundae",
      "Raspberry",
    ],
  },

  "Technology & Software": {
    "Programming Languages": [
      "JavaScript",
      "Python",
      "Java",
      "C++",
      "Ruby",
      "C#",
      "Go",
      "Swift",
      "PHP",
      "Kotlin",
    ],
    "Social Media Platforms": [
      "Facebook",
      "Instagram",
      "Twitter (X)",
      "LinkedIn",
      "Snapchat",
      "TikTok",
      "Reddit",
      "Pinterest",
      "YouTube",
      "WhatsApp",
    ],
    "Video Game Consoles": [
      "PlayStation 5",
      "Xbox Series X",
      "Nintendo Switch",
      "PlayStation 4",
      "Xbox One",
      "Nintendo Wii",
      "Nintendo 3DS",
      "PlayStation 3",
      "Xbox 360",
      "Nintendo GameCube",
    ],
    "Travel Apps": [
      "Airbnb",
      "TripAdvisor",
      "Skyscanner",
      "Google Maps",
      "Hopper",
      "Expedia",
      "Booking.com",
      "Kayak",
      "Uber",
      "Yelp",
    ],
    "Smartphone Brands": [
      "Apple",
      "Samsung",
      "Google",
      "OnePlus",
      "Xiaomi",
      "Huawei",
      "Sony",
      "LG",
      "Nokia",
      "Motorola",
    ],
  },
  "Leisure & Recreation": {
    "Ski Resorts": [
      "Aspen, USA",
      "Whistler Blackcomb, Canada",
      "Zermatt, Switzerland",
      "St. Anton, Austria",
      "Niseko, Japan",
      "Vail, USA",
      "Banff, Canada",
      "Chamonix, France",
      "Cortina d'Ampezzo, Italy",
      "Val d'Isère, France",
    ],
  },

  "Entertainment": {
    "Genres of Music": [
      "Rock",
      "Pop",
      "Jazz",
      "Classical",
      "Hip-Hop",
      "Electronic",
      "Country",
      "Blues",
      "Reggae",
      "Metal",
    ],
    "Podcasts": [
      "The Joe Rogan Experience",
      "Serial",
      "Stuff You Should Know",
      "The Daily",
      "Radiolab",
      "Freakonomics Radio",
      "How I Built This",
      "The Tim Ferriss Show",
      "Crime Junkie",
      "My Favorite Murder",
    ],
    "Types of Movies": [
      "Action",
      "Comedy",
      "Drama",
      "Horror",
      "Sci-Fi",
      "Romantic",
      "Thriller",
      "Documentary",
      "Animation",
      "Fantasy",
    ],
    "Netflix Originals": [
      "Stranger Things",
      "The Crown",
      "Ozark",
      "The Witcher",
      "House of Cards",
      "Narcos",
      "Money Heist",
      "Bridgerton",
      "The Queen's Gambit",
      "Black Mirror",
    ],
    "Michael Bay Films": [
      "Transformers",
      "Armageddon",
      "Pearl Harbor",
      "The Rock",
      "Bad Boys",
      "Transformers: Revenge of the Fallen",
      "Transformers: Dark of the Moon",
      "Pain & Gain",
      "13 Hours: The Secret Soldiers of Benghazi",
      "Transformers: Age of Extinction",
    ],
    "Harry Potter Films": [
      "Harry Potter and the Sorcerer's Stone",
      "Harry Potter and the Chamber of Secrets",
      "Harry Potter and the Prisoner of Azkaban",
      "Harry Potter and the Goblet of Fire",
      "Harry Potter and the Order of the Phoenix",
      "Harry Potter and the Half-Blood Prince",
      "Harry Potter and the Deathly Hallows – Part 1",
      "Harry Potter and the Deathly Hallows – Part 2",
    ],
    "Star Wars Films": [
      "Star Wars: Episode IV – A New Hope",
      "Star Wars: Episode V – The Empire Strikes Back",
      "Star Wars: Episode VI – Return of the Jedi",
      "Star Wars: Episode I – The Phantom Menace",
      "Star Wars: Episode II – Attack of the Clones",
      "Star Wars: Episode III – Revenge of the Sith",
      "Star Wars: Episode VII – The Force Awakens",
      "Star Wars: Episode VIII – The Last Jedi",
      "Star Wars: Episode IX – The Rise of Skywalker",
      "Rogue One: A Star Wars Story",
    ],
    "Oscar-Nominated Movies": [
      "The Godfather",
      "Forrest Gump",
      "Schindler's List",
      "The Shawshank Redemption",
      "Titanic",
      "Pulp Fiction",
      "The Lord of the Rings: The Return of the King",
      "Gladiator",
      "La La Land",
      "Parasite",
    ],
    "Animated TV Shows": [
      "The Simpsons",
      "Rick and Morty",
      "Avatar: The Last Airbender",
      "Family Guy",
      "South Park",
      "Bob's Burgers",
      "Futurama",
      "Adventure Time",
      "Archer",
      "Bojack Horseman",
    ],
    "Horror Movies": [
      "The Exorcist",
      "Halloween",
      "A Nightmare on Elm Street",
      "The Shining",
      "Psycho",
      "The Conjuring",
      "It",
      "Get Out",
      "The Texas Chainsaw Massacre",
      "Hereditary",
    ],
    "Romantic Comedies": [
      "When Harry Met Sally",
      "Pretty Woman",
      "10 Things I Hate About You",
      "Notting Hill",
      "Love Actually",
      "The Proposal",
      "You've Got Mail",
      "Sleepless in Seattle",
      "Bridget Jones's Diary",
      "Crazy Rich Asians",
    ],
    "Action Movies": [
      "Die Hard",
      "Mad Max: Fury Road",
      "Gladiator",
      "The Dark Knight",
      "Terminator 2: Judgment Day",
      "John Wick",
      "Lethal Weapon",
      "The Matrix",
      "Indiana Jones: Raiders of the Lost Ark",
      "Kill Bill: Vol. 1",
    ],
    "Sci-Fi Movies": [
      "Blade Runner",
      "The Matrix",
      "Inception",
      "Star Wars: A New Hope",
      "The Terminator",
      "Interstellar",
      "Back to the Future",
      "2001: A Space Odyssey",
      "Jurassic Park",
      "E.T. the Extra-Terrestrial",
    ],
    "80s Movies": [
      "The Breakfast Club",
      "Ferris Bueller's Day Off",
      "E.T. the Extra-Terrestrial",
      "Back to the Future",
      "Ghostbusters",
      "The Goonies",
      "Top Gun",
      "Die Hard",
      "Blade Runner",
      "Indiana Jones: Raiders of the Lost Ark",
    ],
    "Documentaries": [
      "Planet Earth",
      "The Last Dance",
      "Won't You Be My Neighbor?",
      "13th",
      "Free Solo",
      "Making a Murderer",
      "The Social Dilemma",
      "The Cove",
      "Blackfish",
      "Icarus",
    ],
    "Miniseries": [
      "Chernobyl",
      "Band of Brothers",
      "The Queen's Gambit",
      "Sharp Objects",
      "Big Little Lies",
      "The Night Of",
      "Fargo",
      "The Pacific",
      "True Detective",
      "The Undoing",
    ],
    "Streaming Service Originals": [
      "Stranger Things",
      "The Mandalorian",
      "The Crown",
      "The Boys",
      "The Witcher",
      "The Queen's Gambit",
      "Ozark",
      "The Handmaid's Tale",
      "Mindhunter",
      "Narcos",
    ],
    "Award-Winning TV Shows": [
      "Breaking Bad",
      "The Sopranos",
      "The West Wing",
      "Mad Men",
      "Game of Thrones",
      "The Crown",
      "Fleabag",
      "The Marvelous Mrs. Maisel",
      "Schitt's Creek",
      "The Handmaid's Tale",
    ],
    "Historical Dramas": [
      "Braveheart",
      "Gladiator",
      "Schindler's List",
      "12 Years a Slave",
      "The King's Speech",
      "Lincoln",
      "The Imitation Game",
      "Dunkirk",
      "The Last of the Mohicans",
      "The Patriot",
    ],
    "Superhero Movies": [
      "The Dark Knight",
      "Iron Man",
      "Avengers: Endgame",
      "Spider-Man: Into the Spider-Verse",
      "Logan",
      "Wonder Woman",
      "Guardians of the Galaxy",
      "Black Panther",
      "The Avengers",
      "Superman",
    ],
    "Sitcoms": [
      "Friends",
      "The Office",
      "Seinfeld",
      "Parks and Recreation",
      "How I Met Your Mother",
      "Brooklyn Nine-Nine",
      "The Big Bang Theory",
      "Frasier",
      "Cheers",
      "Arrested Development",
    ],
    "Reality TV Shows": [
      "Survivor",
      "The Bachelor",
      "The Amazing Race",
      "Big Brother",
      "Keeping Up with the Kardashians",
      "The Real Housewives",
      "RuPaul's Drag Race",
      "American Idol",
      "Top Chef",
      "Project Runway",
    ],
    "TV Shows from the 90s": [
      "Friends",
      "The X-Files",
      "Buffy the Vampire Slayer",
      "Seinfeld",
      "Fresh Prince of Bel-Air",
      "Twin Peaks",
      "ER",
      "The Simpsons",
      "Frasier",
      "Boy Meets World",
    ],
    "Foreign Films": [
      "Parasite",
      "Amélie",
      "Pan's Labyrinth",
      "City of God",
      "The Lives of Others",
      "Oldboy",
      "Cinema Paradiso",
      "Crouching Tiger, Hidden Dragon",
      "Life is Beautiful",
      "Rashomon",
    ],
    "Nickelodeon TV Shows": [
      "SpongeBob SquarePants",
      "Rugrats",
      "Hey Arnold!",
      "The Fairly OddParents",
      "Avatar: The Last Airbender",
      "Drake & Josh",
      "iCarly",
      "All That",
      "The Adventures of Jimmy Neutron: Boy Genius",
      "Zoey 101",
    ],
    "Disney Movies": [
      "The Lion King",
      "Beauty and the Beast",
      "Aladdin",
      "Frozen",
      "The Little Mermaid",
      "Moana",
      "Mulan",
      "Zootopia",
      "Tangled",
      "Cinderella",
    ],
    "Pixar Movies": [
      "Toy Story",
      "Finding Nemo",
      "The Incredibles",
      "Up",
      "Ratatouille",
      "Monsters, Inc.",
      "WALL-E",
      "Inside Out",
      "Coco",
      "Cars",
    ],
  },

  "Global Cultures & Cuisines": {
    "Types of Cuisine": [
      "Italian",
      "Mexican",
      "Chinese",
      "Japanese",
      "Indian",
      "French",
      "Thai",
      "Greek",
      "Spanish",
      "Korean",
    ],
    "Geographic Biomes": [
      "Desert",
      "Rainforest",
      "Tundra",
      "Grassland",
      "Savanna",
      "Taiga",
      "Temperate Forest",
      "Alpine",
      "Chaparral",
      "Wetlands",
    ],
    "Fruits": [
      "Apple",
      "Banana",
      "Orange",
      "Mango",
      "Pineapple",
      "Grapes",
      "Peach",
      "Strawberry",
      "Cherry",
      "Kiwi",
    ],
    "Berries": [
      "Strawberry",
      "Blueberry",
      "Raspberry",
      "Blackberry",
      "Goji Berry",
      "Cranberry",
      "Elderberry",
      "Acai Berry",
      "Mulberry",
      "Gooseberry",
    ],
    "Melons": [
      "Watermelon",
      "Cantaloupe",
      "Honeydew",
      "Galia Melon",
      "Santa Claus Melon",
      "Canary Melon",
      "Crenshaw Melon",
      "Charentais Melon",
      "Hami Melon",
      "Bitter Melon",
    ],
    "Nuts": [
      "Almond",
      "Cashew",
      "Walnut",
      "Pecan",
      "Hazelnut",
      "Pistachio",
      "Macadamia",
      "Brazil Nut",
      "Chestnut",
      "Pine Nut",
    ],
  },

  "Transportation & Travel": {
    "Hotel Chains": [
        "Marriott",
        "Hilton",
        "Hyatt",
        "InterContinental",
        "Sheraton",
        "Radisson",
        "Holiday Inn",
        "Four Seasons",
        "Best Western",
        "Wyndham"
      ],
    "Modes of Transportation": [
      "Car",
      "Bicycle",
      "Train",
      "Airplane",
      "Bus",
      "Motorcycle",
      "Boat",
      "Tram",
      "Subway",
      "Walking",
    ],
    "Motorcycle Brands": [
      "Harley-Davidson",
      "Ducati",
      "Yamaha",
      "Kawasaki",
      "Honda",
      "BMW Motorrad",
      "Suzuki",
      "Triumph",
      "Royal Enfield",
      "Indian Motorcycle",
    ],

    "Vacation Destinations": [
      "Hawaii",
      "Paris",
      "New York City",
      "Tokyo",
      "Sydney",
      "Rome",
      "Cancun",
      "London",
      "Bali",
      "Dubai",
    ],
    "Adventure Travel Destinations": [
      "Machu Picchu, Peru",
      "Great Barrier Reef, Australia",
      "Patagonia, Argentina/Chile",
      "Banff National Park, Canada",
      "Mount Kilimanjaro, Tanzania",
      "Galápagos Islands, Ecuador",
      "Iceland's Ring Road",
      "The Alps, Europe",
      "Grand Canyon, USA",
      "Antarctica",
    ],
    "Budget Travel Destinations": [
      "Thailand",
      "Vietnam",
      "Portugal",
      "Mexico",
      "Greece",
      "India",
      "Indonesia",
      "Romania",
      "Turkey",
      "Colombia",
    ],
    "Exotic Vacation Spots": [
      "Bora Bora, French Polynesia",
      "Maldives",
      "Santorini, Greece",
      "Bali, Indonesia",
      "Fiji",
      "Seychelles",
      "Maui, Hawaii",
      "Phuket, Thailand",
      "Marrakech, Morocco",
      "Cape Town, South Africa",
    ],
    "Road Trip Destinations": [
      "Route 66, USA",
      "Pacific Coast Highway, USA",
      "Great Ocean Road, Australia",
      "Ring Road, Iceland",
      "Amalfi Coast, Italy",
      "Garden Route, South Africa",
      "North Coast 500, Scotland",
      "Cabot Trail, Canada",
      "Atlantic Road, Norway",
      "Causeway Coastal Route, Northern Ireland",
    ],
    "Historic Landmarks": [
      "Eiffel Tower, France",
      "Colosseum, Italy",
      "Great Wall of China",
      "Machu Picchu, Peru",
      "Taj Mahal, India",
      "Statue of Liberty, USA",
      "Stonehenge, UK",
      "Pyramids of Giza, Egypt",
      "Acropolis, Greece",
      "Christ the Redeemer, Brazil",
    ],

    "Luxury Car Brands": [
      "Mercedes-Benz",
      "BMW",
      "Audi",
      "Lexus",
      "Porsche",
      "Jaguar",
      "Bentley",
      "Rolls-Royce",
      "Maserati",
      "Tesla",
    ],
    "Car Rental Companies": [
      "Hertz",
      "Avis",
      "Enterprise",
      "Budget",
      "National",
      "Alamo",
      "Thrifty",
      "Sixt",
      "Dollar",
      "Europcar",
    ],
    "Scenic Train Routes": [
      "Rocky Mountaineer, Canada",
      "Glacier Express, Switzerland",
      "Trans-Siberian Railway, Russia",
      "The Ghan, Australia",
      "Belmond Royal Scotsman, Scotland",
      "West Highland Line, Scotland",
      "Flam Railway, Norway",
      "Bernina Express, Switzerland/Italy",
      "Jacobite Steam Train, Scotland",
      "Durango & Silverton Narrow Gauge Railroad, USA",
    ],
    "Public Transportation Systems": [
      "New York City Subway",
      "London Underground",
      "Paris Métro",
      "Tokyo Metro",
      "Berlin U-Bahn",
      "Toronto TTC",
      "San Francisco BART",
      "Chicago 'L'",
      "Singapore MRT",
      "Hong Kong MTR",
    ],
    "Train Services": [
        "Amtrak",
        "Eurostar",
        "Shinkansen",
        "TGV",
        "Deutsche Bahn",
        "Thalys",
        "Via Rail",
        "Indian Railways",
        "Renfe",
        "Trans-Siberian Railway"
      ]

  },

  "Luxury & Lifestyle": {
    "Watch Brands": [
      "Rolex",
      "Omega",
      "Tag Heuer",
      "Casio",
      "Seiko",
      "Patek Philippe",
      "Breitling",
      "Audemars Piguet",
      "Tissot",
      "Fossil",
    ],
    "Shampoo Brands": [
      "Pantene",
      "Head & Shoulders",
      "Garnier Fructis",
      "Herbal Essences",
      "TRESemmé",
      "Dove",
      "Aussie",
      "OGX",
      "L'Oréal Paris",
      "Suave",
    ],
    "Chocolate Brands": [
      "Hershey's",
      "Cadbury",
      "Lindt",
      "Ghirardelli",
      "Godiva",
      "Toblerone",
      "Ferrero Rocher",
      "Nestlé",
      "Mars",
      "Milka",
    ]
  },

  "Animals & Nature": {
    "Animals": [
        "Lion",
        "Elephant",
        "Tiger",
        "Giraffe",
        "Kangaroo",
        "Panda",
        "Dolphin",
        "Eagle",
        "Penguin",
        "Zebra"
      ],
    "Pets": [
      "Dog",
      "Cat",
      "Hamster",
      "Rabbit",
      "Fish",
      "Parrot",
      "Guinea Pig",
      "Turtle",
      "Ferret",
      "Chinchilla",
    ],
    "Types of Birds": [
      "Sparrow",
      "Pigeon",
      "Robin",
      "Blue Jay",
      "Cardinal",
      "Crow",
      "Dove",
      "Owl",
      "Eagle",
      "Hawk",
    ],
    "Types of Fish": [
      "Goldfish",
      "Betta Fish",
      "Guppy",
      "Angelfish",
      "Tetra",
      "Molly",
      "Cichlid",
      "Barb",
      "Catfish",
      "Plecostomus",
    ],
    "Types of Reptiles": [
      "Snake",
      "Lizard",
      "Turtle",
      "Crocodile",
      "Alligator",
      "Iguana",
      "Chameleon",
      "Gecko",
      "Skink",
      "Monitor",
    ],
    "Types of Mammals": [
      "Dog",
      "Cat",
      "Elephant",
      "Lion",
      "Tiger",
      "Bear",
      "Whale",
      "Dolphin",
      "Kangaroo",
      "Giraffe",
    ],
    "Types of Insects": [
      "Ant",
      "Bee",
      "Butterfly",
      "Beetle",
      "Dragonfly",
      "Grasshopper",
      "Mosquito",
      "Moth",
      "Wasp",
      "Cockroach",
    ],
    "Types of Trees": [
      "Oak",
      "Maple",
      "Pine",
      "Birch",
      "Willow",
      "Cherry",
      "Elm",
      "Cedar",
      "Ash",
      "Redwood",
    ],
    "Types of Flowers": [
      "Rose",
      "Daisy",
      "Tulip",
      "Sunflower",
      "Lily",
      "Orchid",
      "Carnation",
      "Hydrangea",
      "Daffodil",
      "Peony",
    ],


  },

  "Science & Knowledge": {
    "Types of Math": [
      "Algebra",
      "Geometry",
      "Calculus",
      "Trigonometry",
      "Statistics",
      "Probability",
      "Number Theory",
      "Linear Algebra",
      "Differential Equations",
      "Discrete Mathematics",
    ],
    "Sciences": [
      "Physics",
      "Chemistry",
      "Biology",
      "Astronomy",
      "Geology",
      "Meteorology",
      "Ecology",
      "Genetics",
      "Oceanography",
      "Botany",
    ],
  },

  "Historical & Mythological": {
    "Ancient Civilizations": [
      "Mesopotamia",
      "Ancient Egypt",
      "Indus Valley",
      "Ancient Greece",
      "Ancient Rome",
      "Maya",
      "Aztec",
      "Inca",
      "Babylon",
      "Persia",
    ],
    "Deities": [
      "Zeus",
      "Odin",
      "Ra",
      "Vishnu",
      "Shiva",
      "Hera",
      "Thor",
      "Isis",
      "Anubis",
      "Apollo",
    ],
    "Greek Gods": [
      "Zeus",
      "Hera",
      "Poseidon",
      "Demeter",
      "Athena",
      "Apollo",
      "Artemis",
      "Ares",
      "Aphrodite",
      "Hephaestus",
    ],
  },

  "Psychology & Beliefs": {
    "Cognitive Biases": [
      "Confirmation Bias",
      "Anchoring Bias",
      "Hindsight Bias",
      "Availability Heuristic",
      "Dunning-Kruger Effect",
      "Self-Serving Bias",
      "Negativity Bias",
      "Sunk Cost Fallacy",
      "Ingroup Bias",
      "Framing Effect",
    ],
    "Conspiracy Theories": [
      "Area 51",
      "Moon Landing Hoax",
      "Flat Earth",
      "JFK Assassination",
      "9/11 Inside Job",
      "New World Order",
      "Illuminati",
      "Chemtrails",
      "Reptilian Overlords",
      "Pizzagate",
    ],
  },

  "Combat & Power": {
    "Guns": [
      "AK-47",
      "M16",
      "Glock 17",
      "Desert Eagle",
      "Colt 1911",
      "Remington 870",
      "Winchester Model 70",
      "Beretta 92FS",
      "Smith & Wesson Model 29",
      "FN SCAR",
    ],
    "Superpowers": [
      "Super Strength",
      "Telekinesis",
      "Invisibility",
      "Flight",
      "Time Manipulation",
      "Teleportation",
      "Super Speed",
      "Mind Control",
      "Invulnerability",
      "Shape-shifting",
    ],
  },
};

export default categories;