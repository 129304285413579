import React, { useState } from 'react';
import { signInWithEmailAndPassword } from 'firebase/auth';
import './LoginModal.css';
import { useNavigate } from 'react-router-dom';

function LoginModal({ auth, setUser, show, onClose, setSuccessMessage, setError }) {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const navigate = useNavigate(); // Initialize useNavigate

  const handleLogin = async (e) => {
    e.preventDefault();
    setError(''); // Clear previous error messages
    setSuccessMessage(''); // Clear previous success messages
    try {
      const userCredential = await signInWithEmailAndPassword(auth, email, password);
      setUser(userCredential.user);
      setSuccessMessage('Login successful! Redirecting...'); // Show success message
      onClose(); // Close the modal on successful login
      navigate('/'); // Redirect to home
    } catch (error) {
      console.error("Error logging in:", error.message);
      setError("Error logging in: " + error.message); // Display error message in UI
    }
  };

  if (!show) return null;

  return (
    <div className="modal-backdrop">
      <div className="modal-content">
        <h2>Login</h2>
        <form onSubmit={handleLogin}>
          <input
            type="email"
            placeholder="Email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          <input
            type="password"
            placeholder="Password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
          <button type="submit">Login</button>
        </form>
        <button onClick={onClose}>Close</button>
      </div>
    </div>
  );
}

export default LoginModal;