import React, { useState, useEffect } from 'react';
import { db } from './firebase';
import { doc, setDoc, getDoc, collection, query, where, getDocs } from 'firebase/firestore';
import './CreateUsername.css';

const CreateUsername = ({ onUsernameCreated, isGuest, userEmail }) => {
  const [username, setUsername] = useState('');
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');

  useEffect(() => {
    if (success || error) {
      const timer = setTimeout(() => {
        setSuccess('');
        setError('');
      }, 3000); // 3 seconds delay
      return () => clearTimeout(timer);
    }
  }, [success, error]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (username.trim() === '') {
      setError('Username cannot be empty');
      return;
    }

    try {
      // Check if the username already exists
      const usernamesQuery = query(collection(db, 'users'), where('username', '==', username.trim()));
      const usernamesSnapshot = await getDocs(usernamesQuery);

      if (!usernamesSnapshot.empty) {
        setError('Username already exists. Please choose a different one.');
        return;
      }

      // Store the username in Firestore under 'users' collection with the user's email as the document ID
      await setDoc(doc(db, 'users', userEmail), {
        username: username.trim(),
        createdAt: new Date()
      });

      setError('');
      setSuccess('Username created successfully!');
      console.log(`Username "${username}" saved successfully to Firestore.`);
      onUsernameCreated(username);  // Call parent handler to update state
    } catch (err) {
      console.error('Error saving username: ', err);
      setError('Failed to create username');
    }
  };

  return (
    <div className={`create-username ${isGuest ? 'guest-disabled' : ''}`}>
      <h2>Create Username</h2>
      <form onSubmit={handleSubmit}>
        <div className="input-button-container">
          <input
            type="text"
            className="username-input"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
            placeholder="Enter username"
          />
          <button type="submit" className="submit-button">
            Save
          </button>
        </div>
        {error && <div className={`error ${error ? 'fade-out' : ''}`}>{error}</div>}
        {success && <div className={`success ${success ? 'fade-out' : ''}`}>{success}</div>}
      </form>
    </div>
  );
};

export default CreateUsername;