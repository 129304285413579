import React, { useState, useEffect, useRef } from "react";
import { useNavigate, Link, useLocation } from "react-router-dom";
import {
  getFirestore,
  collection,
  getDocs,
  setDoc,
  doc,
} from "firebase/firestore";
import { signOut } from "firebase/auth";
import html2canvas from "html2canvas";
import Modal from "./Modal";
import categories from "./categories-meta";
import "./Homepage.css";
import Suggestion from "./suggestion";
import exit from "./exit.png";

const Homepage = ({ auth }) => {
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [tiers, setTiers] = useState({
    S: [],
    A: [],
    B: [],
    C: [],
    D: [],
    F: [],
  });
  const [currentOptionIndex, setCurrentOptionIndex] = useState(0);
  const [rankedOptions, setRankedOptions] = useState([]);
  const [startTime, setStartTime] = useState(null);
  const [endTime, setEndTime] = useState(null);
  const [showModal, setShowModal] = useState(true);
  const [completedCategories, setCompletedCategories] = useState([]);
  const [customCategories, setCustomCategories] = useState({});
  const nextOptionRef = useRef(null);
  const selectionMenuRef = useRef(null);
  const navigate = useNavigate();
  const location = useLocation();
  const user = auth.currentUser;
  const db = getFirestore();

  useEffect(() => {
    if (!user) {
      setShowModal(true);
    } else {
      setShowModal(false);
    }
  }, [user]);

  useEffect(() => {
    console.log("Location State:", location.state); // Check the full state object

    if (location.state?.sharedCategory && location.state?.sharedOptions) {
      console.log("Shared Category:", location.state.sharedCategory); // Verify category
      console.log("Shared Options:", location.state.sharedOptions); // Verify options
      handleCategorySelect(
        location.state.sharedCategory,
        location.state.sharedOptions
      );
      setShowModal(false); // Ensure the modal doesn't show
    }
  }, [location.state]);

  const handleCategorySelect = (category, options) => {
    console.log("Selected Category:", category); // Confirm the correct category
    console.log("Selected Options:", options); // Confirm the correct options

    setSelectedCategory(category);
    setSelectedOptions(options);
    setTiers({ S: [], A: [], B: [], C: [], D: [], F: [] });
    setCurrentOptionIndex(0);
    setRankedOptions([]);
    setStartTime(Date.now());
    setEndTime(null);
    setShowModal(false); // Close the modal when a category is selected
  };

  useEffect(() => {
    const fetchCategories = async () => {
      if (user) {
        try {
          const rankingsCollection = collection(
            db,
            "rankings",
            user.email,
            "userRankings"
          );
          const rankingsSnapshot = await getDocs(rankingsCollection);
          const completed = rankingsSnapshot.docs.map((doc) => doc.id);
          setCompletedCategories(completed);

          const customCategoriesCollection = collection(
            db,
            "customCategories",
            user.email,
            "userCategories"
          );
          const customCategoriesSnapshot = await getDocs(
            customCategoriesCollection
          );
          const customCategoriesData = customCategoriesSnapshot.docs.reduce(
            (acc, doc) => ({
              ...acc,
              [doc.id]: {
                options: Array.isArray(doc.data().options)
                  ? doc.data().options
                  : [],
                customCategoryName:
                  doc.data().customCategoryName || "Unnamed Category",
              },
            }),
            {}
          );
          setCustomCategories(customCategoriesData);
        } catch (error) {
          console.error("Error fetching categories:", error);
        }
      }
    };
    fetchCategories();
  }, [user, db]);

  useEffect(() => {
    if (selectedCategory && selectionMenuRef.current) {
      selectionMenuRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [selectedCategory]);

  const addCategoryWithOptions = async (
    newCategory = "Unnamed Category",
    options
  ) => {
    if (user) {
      try {
        const customCategoryRef = doc(
          collection(db, "customCategories", user.email, "userCategories"),
          newCategory
        );
        await setDoc(customCategoryRef, {
          customCategoryName: newCategory,
          options,
        });
        setCustomCategories((prev) => ({
          ...prev,
          [newCategory]: { options, customCategoryName: newCategory },
        }));
      } catch (error) {
        console.error("Error saving custom category:", error);
      }
    }
  };

  const handleSignOut = async () => {
    try {
      await signOut(auth);
      navigate("/auth");
    } catch (error) {
      console.error("Error signing out:", error);
    }
  };

  const formatTime = (milliseconds) => {
    const totalSeconds = Math.floor(milliseconds / 1000);
    const minutes = Math.floor(totalSeconds / 60);
    const seconds = totalSeconds % 60;
    return `${minutes}m ${seconds}s`;
  };

  const moveOptionToTier = (option, tier) => {
    console.log("Moving option:", option, "to tier:", tier); // Verify tier movement

    setTiers((prevTiers) => {
      const newTiers = { ...prevTiers };
      // Remove the option from all tiers and add it to the selected tier
      Object.keys(newTiers).forEach((key) => {
        newTiers[key] = newTiers[key].filter((item) => item !== option);
      });
      newTiers[tier].push(option); // Add the option to the selected tier
      return newTiers;
    });

    setRankedOptions((prev) => [
      ...prev.filter((item) => item.option !== option),
      { option, tier },
    ]);

    setCurrentOptionIndex((prev) => {
      const newIndex = prev + 1;
      console.log(
        "New Index:",
        newIndex,
        "Total Options:",
        selectedOptions.length
      );
      if (newIndex === selectedOptions.length) {
        setEndTime(Date.now());
        handleRankingComplete();
      }
      return newIndex;
    });
  };

  const handleRankingComplete = async () => {
    console.log("Ranking complete for:", selectedCategory);
    setCompletedCategories((prev) => [...prev, selectedCategory]);
  
    // Find the meta-category from the categories-meta.js file
    let metaCategory = null;
    for (let key in categories) {
      if (categories[key][selectedCategory]) {
        metaCategory = key; // Set the meta-category (e.g., "Beverages", "Technology & Software")
        break;
      }
    }
  
    if (user) {
      const rankingData = {
        category: selectedCategory,
        tiers,
        timestamp: new Date(),
        customCategoryName:
          customCategories[selectedCategory]?.customCategoryName || null,
        metaCategory: metaCategory || "Default MetaCategory", // Use the derived meta-category
      };
  
      try {
        const userRankingsRef = doc(
          collection(db, "rankings", user.email, "userRankings"),
          selectedCategory
        );
        await setDoc(userRankingsRef, rankingData);
        console.log("Ranking saved successfully!");
      } catch (error) {
        console.error("Error saving ranking:", error);
      }
    }
  };

  const handleDownloadImage = () => {
    const element = document.getElementById("tier-list");
    html2canvas(element).then((canvas) => {
      const link = document.createElement("a");
      link.download = "tier-list.png";
      link.href = canvas.toDataURL("image/png");
      link.click();
    });
  };

  const shareCategory = async (category, recipientEmail) => {
    if (!recipientEmail) return;

    const message = {
      from: user.email,
      category: category.name, // Ensure 'category' and 'options' are both correctly passed
      options: category.options, // Make sure this exists
      timestamp: new Date(),
    };

    try {
      const inboxRef = doc(collection(db, "inbox", recipientEmail, "messages"));
      await setDoc(inboxRef, message);
      alert("Category shared successfully!");
    } catch (error) {
      console.error("Error sharing category:", error);
      alert("Failed to share category.");
    }
  };

  return (
    <div>
      <h1>Tier Ranking App</h1>

      <div className="header">
        <span className="username">
          {user ? `Hello, ${user.isAnonymous ? "Guest" : user.email}` : ""}
        </span>
        <button className="exit-button" onClick={handleSignOut}>
          <img src={exit} alt="Exit" className="exit-icon" />
        </button>
      </div>

      {!user.isAnonymous && <Link to="/profile">Go to Profile</Link>}

      <div className="categories">
        <h2>Select a Category</h2>
        {/* Loop through all meta-categories, but only display sub-categories */}
        {Object.keys(categories).map((metaCategory) =>
          Object.keys(categories[metaCategory]).map((subCategory) => (
            <button
              key={subCategory}
              onClick={() =>
                handleCategorySelect(
                  subCategory,
                  categories[metaCategory][subCategory]
                )
              }
              disabled={completedCategories.includes(subCategory)}
              className={
                completedCategories.includes(subCategory)
                  ? "disabled-category"
                  : ""
              }
            >
              {subCategory} {/* Display the sub-category */}
            </button>
          ))
        )}
      </div>

      <div className="custom-categories">
        <h2>Your Custom Categories</h2>
        {Object.keys(customCategories).map((category) => (
          <button
            key={category}
            onClick={() =>
              handleCategorySelect(category, customCategories[category].options)
            }
            className={
              completedCategories.includes(category) ? "disabled-category" : ""
            }
          >
            {category}
          </button>
        ))}
      </div>

      <Suggestion addCategoryWithOptions={addCategoryWithOptions} />

      {selectedCategory && (
        <>
          <h2 ref={selectionMenuRef}>{selectedCategory}</h2>
          <div className="options">
            <h3>Options</h3>
            <ul>
              {selectedOptions.map(
                (option, index) =>
                  index <= currentOptionIndex && (
                    <li
                      key={option}
                      ref={index === currentOptionIndex ? nextOptionRef : null}
                    >
                      {option}
                      <div className="buttons">
                        {Object.keys(tiers).map((tier) => (
                          <button
                            key={tier}
                            onClick={() => moveOptionToTier(option, tier)}
                            disabled={index < currentOptionIndex}
                          >
                            {tier}
                          </button>
                        ))}
                      </div>
                    </li>
                  )
              )}
            </ul>
          </div>

          <div id="tier-list" className="tiers">
            {Object.keys(tiers).map((tier) => (
              <div key={tier} className="tier">
                <h3>Tier {tier}</h3>
                <ul>
                  {tiers[tier].map((option) => (
                    <li key={option}>{option}</li>
                  ))}
                </ul>
              </div>
            ))}
            <div className="footer">
              <p>Category: {selectedCategory}</p>
              {endTime && <p>Time Taken: {formatTime(endTime - startTime)}</p>}
            </div>
          </div>

          <button id="download-button" onClick={handleDownloadImage}>
            Download Image
          </button>
        </>
      )}

      <Modal show={showModal} onClose={() => setShowModal(false)} />
    </div>
  );
};

export default Homepage;
