import React, { useState, useEffect } from "react";
import {
  getFirestore,
  collection,
  query,
  where,
  getDocs,
  getDoc,
  setDoc,
  doc,
  deleteDoc,
} from "firebase/firestore";
import { getAuth } from "firebase/auth";
import "./Community.css"; // Ensure you have appropriate styles
import defaultAvatar from "./defaultAvatar.jpeg"; // Path to your default avatar

const Community = () => {
  const [queryText, setQueryText] = useState("");
  const [results, setResults] = useState([]);
  const [friends, setFriends] = useState([]);
  const [friendRequests, setFriendRequests] = useState([]);
  const [confirmationMessage, setConfirmationMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState('');
  const [selectedFriend, setSelectedFriend] = useState(null); // Track selected friend for deletion
  const [showDeleteModal, setShowDeleteModal] = useState(false); // Modal state
  const [fadeOut, setFadeOut] = useState(false);

  const db = getFirestore();
  const auth = getAuth();
  const user = auth.currentUser;

  useEffect(() => {
    if (user) {
      fetchFriends();
      fetchFriendRequests();
    }
  }, [user]);

  useEffect(() => {
    if (confirmationMessage || errorMessage) {
      setFadeOut(false);
      const timer = setTimeout(() => {
        setFadeOut(true);
        const fadeTimer = setTimeout(() => {
          setConfirmationMessage('');
          setErrorMessage('');
        }, 500); // 0.5 seconds delay for fade out
        return () => clearTimeout(fadeTimer);
      }, 3000); // 3 seconds delay
      return () => clearTimeout(timer);
    }
  }, [confirmationMessage, errorMessage]);

  const fetchFriends = async () => {
    try {
      const friendsRef = collection(db, "users", user.email, "friends");
      const snapshot = await getDocs(friendsRef);
      const friendsList = snapshot.docs.map((doc) => doc.data());
      setFriends(friendsList);
    } catch (error) {
      console.error("Error fetching friends:", error);
    }
  };

  const fetchFriendRequests = async () => {
    try {
      const requestsRef = collection(db, "users", user.email, "friendRequests");
      const snapshot = await getDocs(requestsRef);
      const requestsList = snapshot.docs.map((doc) => doc.data());
      setFriendRequests(requestsList);
    } catch (error) {
      console.error("Error fetching friend requests:", error);
    }
  };

  const getUsername = async () => {
    if (user.displayName) {
      return user.displayName;
    }
    try {
      const userDoc = await getDoc(doc(db, "users", user.email));
      if (userDoc.exists()) {
        return userDoc.data().username;
      }
    } catch (error) {
      console.error("Error fetching username:", error);
    }
    return "Unknown User"; // Fallback in case no username is found
  };

  const handleSendFriendRequest = async (username) => {
    if (friends.some((friend) => friend.username === username)) {
      setConfirmationMessage(`${username} is already your friend.`);
      return;
    }

    try {
      const usersRef = collection(db, "users");
      const q = query(usersRef, where("username", "==", username));
      const snapshot = await getDocs(q);

      if (!snapshot.empty) {
        const recipientDoc = snapshot.docs[0];
        const recipientEmail = recipientDoc.id;

        const senderUsername = await getUsername();

        const requestDocRef = doc(
          db,
          "users",
          recipientEmail,
          "friendRequests",
          user.email
        );
        await setDoc(requestDocRef, {
          from: user.email,
          username: senderUsername,
        });

        setConfirmationMessage(`Friend request sent to ${username}`);
        console.log("Friend request sent to:", username);
      } else {
        setErrorMessage("User not found");
        console.error("User not found");
      }
    } catch (error) {
      setErrorMessage('Error sending friend request');
      console.error("Error sending friend request:", error);
    }
  };

  const handleRemoveFriendRequest = async (request) => {
    try {
      const requestDocRef = doc(db, 'users', user.email, 'friendRequests', request.from);
      await deleteDoc(requestDocRef); // Remove the friend request from Firestore
  
      // Update the state to reflect the change
      setFriendRequests((prevRequests) =>
        prevRequests.filter((req) => req.from !== request.from)
      );
  
      setConfirmationMessage(`Friend request from ${request.username} has been removed.`);
    } catch (error) {
      setErrorMessage('Error removing friend request');
      console.error('Error removing friend request:', error);
    }
  };

  const handleAcceptFriendRequest = async (request) => {
    try {
      if (friends.some((friend) => friend.username === request.username)) {
        setConfirmationMessage(`${request.username} is already your friend`);
        return;
      }
  
      const friendAvatarUrl = request.avatarUrl || ""; // Ensure avatarUrl is not undefined
      const userAvatarUrl = user.photoURL || ""; // Ensure avatarUrl is not undefined
  
      const friendDocRef = doc(
        db,
        "users",
        user.email,
        "friends",
        request.from
      );
      await setDoc(friendDocRef, {
        username: request.username,
        avatarUrl: friendAvatarUrl,
      });
  
      const reciprocalFriendDocRef = doc(
        db,
        "users",
        request.from,
        "friends",
        user.email
      );
      const senderUsername = await getUsername();
      await setDoc(reciprocalFriendDocRef, {
        username: senderUsername,
        avatarUrl: userAvatarUrl,
      });
  
      const requestDocRef = doc(
        db,
        "users",
        user.email,
        "friendRequests",
        request.from
      );
      await deleteDoc(requestDocRef);
  
      setFriends((prevFriends) => [
        ...prevFriends,
        { username: request.username, avatarUrl: friendAvatarUrl },
      ]);
      setFriendRequests((prevRequests) =>
        prevRequests.filter((req) => req.from !== request.from)
      );
  
      setConfirmationMessage(`You are now friends with ${request.username}`);
      console.log("Friend request accepted from:", request.username);
    } catch (error) {
      setErrorMessage("Error accepting friend request");
      console.error("Error accepting friend request:", error);
    }
  };

  const handleShowDeleteModal = (friend) => {
    setSelectedFriend(friend);
    setShowDeleteModal(true);
  };

  const handleSearch = async (e) => {
    e.preventDefault();
    console.log("Search initiated with query:", queryText);

    try {
      const usersRef = collection(db, "users");
      const q = query(
        usersRef,
        where("username", ">=", queryText),
        where("username", "<=", queryText + "\uf8ff")
      );
      const snapshot = await getDocs(q);
      const searchResults = snapshot.docs.map((doc) => doc.data());

      console.log("Filtered results:", searchResults);
      setResults(searchResults);
    } catch (error) {
      console.error("Error fetching users:", error);
      setErrorMessage('Error fetching users');

    }
  };

  const handleRemoveFriend = async () => {
    try {
      const friend = selectedFriend;
      const friendDocRef = doc(
        db,
        "users",
        user.email,
        "friends",
        friend.email
      );
      await deleteDoc(friendDocRef);

      const reciprocalFriendDocRef = doc(
        db,
        "users",
        friend.email,
        "friends",
        user.email
      );
      await deleteDoc(reciprocalFriendDocRef);

      setFriends((prevFriends) =>
        prevFriends.filter((f) => f.email !== friend.email)
      );

      setConfirmationMessage(`You have removed ${friend.username}`);
      setShowDeleteModal(false);
      setSelectedFriend(null);
      console.log("Friend removed:", friend.username);
    } catch (error) {
      setErrorMessage("Error removing friend");
      console.error("Error removing friend:", error);
    }
  };

  const handleCloseModal = () => {
    setShowDeleteModal(false);
    setSelectedFriend(null);
  };

  return (
    <div className="community panel">
      <form onSubmit={handleSearch}>
        <input
          type="text"
          value={queryText}
          onChange={(e) => setQueryText(e.target.value)}
          placeholder="Search for a username"
        />
        <button type="submit">Search</button>
      </form>
      <div className="results">
        {results.length === 0 ? (
          <p>No results found</p>
        ) : (
          results.map((user, index) => (
            <div key={index} className="result-item">
              <img
                src={user.avatarUrl || defaultAvatar}
                alt={`${user.username}'s avatar`}
                className="avatar-small"
              />
              <p>Username: {user.username}</p>
              <button onClick={() => handleSendFriendRequest(user.username)}>
                Send Friend Request
              </button>
            </div>
          ))
        )}
      </div>
      {confirmationMessage && (
        <div className="confirmation-message">{confirmationMessage}</div>
      )}
      {errorMessage && (
        <div className={`error-message ${fadeOut ? 'fade-out' : ''}`}>
          {errorMessage}
        </div>
      )}
      <h2>Your Friends</h2>
      {friends.length === 0 ? (
        <p className="no-friends">You have no friends added.</p>
      ) : (
        <div className="friends-grid">
          {friends.map((friend, index) => (
            <div key={index} className="friend-item">
              <img
                src={friend.avatarUrl || defaultAvatar}
                alt={`${friend.username}'s avatar`}
                className="avatar"
              />
              <p>{friend.username}</p>
              <button
                className="delete-button"
                onClick={() => handleShowDeleteModal(friend)}
              >
                remove
              </button>
            </div>
          ))}
        </div>
      )}
      <h2>Friend Requests</h2>
      {friendRequests.length === 0 ? (
        <p className="no-requests">You have no friend requests.</p>
      ) : (
        <div className="requests-grid">
          {friendRequests.map((request, index) => (
            <div key={index} className="request-item">
              <img
                src={request.avatarUrl || defaultAvatar}
                alt={`${request.username}'s avatar`}
                className="avatar"
              />
              <p>{request.username}</p>
              <button onClick={() => handleAcceptFriendRequest(request)}>
                Accept
              </button>
              <button onClick={() => handleRemoveFriendRequest(request)}>
                Remove
              </button>{" "}
              {/* New Remove Button */}
            </div>
          ))}
        </div>
      )}

      {showDeleteModal && (
        <div className="modal">
          <div className="modal-content">
            <p>Are you sure you want to remove {selectedFriend.username}?</p>
            <button className="confirm-button" onClick={handleRemoveFriend}>
              Yes
            </button>
            <button className="cancel-button" onClick={handleCloseModal}>
              No
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default Community;
