// App.js
import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import { onAuthStateChanged } from 'firebase/auth';
import AuthForm from './AuthForm'; // Assuming you have an AuthForm component
import Homepage from './Homepage'; // Import Homepage component
import UserProfile from './UserProfile';

import './App.css';

function App({ auth }) {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true); // State to track loading

  // Use Firebase's onAuthStateChanged to track user authentication state
  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
      setUser(currentUser);
      setLoading(false); // Set loading to false once auth state is determined
    });

    // Cleanup subscription on unmount
    return () => unsubscribe();
  }, [auth]);

  // Show loading state until authentication is determined
  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <div className="App">
    <Router>
      <Routes>
        {/* Redirect to /auth if not logged in */}
        <Route path="/" element={user ? <Homepage auth={auth} /> : <Navigate to="/auth" />} />
        
        {/* Auth Route */}
        <Route path="/auth" element={<AuthForm auth={auth} setUser={setUser} />} />

        <Route path="/profile" element={<UserProfile />} />

        {/* Fallback Route */}
        <Route path="*" element={<Navigate to="/" />} />
      </Routes>
    </Router>
    </div>
  );
}

export default App;