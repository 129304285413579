import React, { useState } from 'react';
import { createUserWithEmailAndPassword, signInAnonymously } from 'firebase/auth';
import { useNavigate } from 'react-router-dom';
import './AuthForm.css'; // Import the CSS file
import LoginModal from './LoginModal'; // Import the LoginModal component

function AuthForm({ auth, setUser }) {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [showLoginModal, setShowLoginModal] = useState(false); // Modal state
  const [successMessage, setSuccessMessage] = useState(''); // State for success messages
  const [error, setError] = useState(''); // State to store error messages
  const navigate = useNavigate(); // Initialize useNavigate

  const handleSignup = async (e) => {
    e.preventDefault();
    setError(''); // Clear previous error messages
    setSuccessMessage(''); // Clear previous success messages
    try {
      const userCredential = await createUserWithEmailAndPassword(auth, email, password);
      setUser(userCredential.user);
      setSuccessMessage('Sign-up successful! You are now logged in.'); // Show success message
      navigate('/'); // Redirect to home after successful sign-up
    } catch (error) {
      console.error("Error creating user:", error.message);
      setError(error.message); // Set error to display in UI
    }
  };

  const handleGuestLogin = async () => {
    setError(''); // Clear previous error messages
    setSuccessMessage(''); // Clear previous success messages
    try {
      const userCredential = await signInAnonymously(auth);
      setUser(userCredential.user);
      setSuccessMessage('Logged in as guest! Redirecting...'); // Show success message
      navigate('/'); // Redirect to home
    } catch (error) {
      console.error("Error logging in as guest:", error.message);
      setError("Error logging in as guest: " + error.message); // Display error message
    }
  };

  return (
    <div className="auth-form-container">
      <h2>Sign Up</h2>
      <form onSubmit={handleSignup}>
        <input
          type="email"
          placeholder="Email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
        <input
          type="password"
          placeholder="Password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        />
        <button type="submit">Sign Up</button>
      </form>

      <button onClick={() => setShowLoginModal(true)}>Login</button>
      <button className="guest-button" onClick={handleGuestLogin}>Join as Guest</button>

      {/* Display error or success messages */}
      {error && <p className="error">{error}</p>}
      {successMessage && <p className="success">{successMessage}</p>}

      {/* Show the Login Modal when the login button is clicked */}
      <LoginModal
        auth={auth}
        setUser={setUser}
        show={showLoginModal}
        onClose={() => setShowLoginModal(false)}
        setSuccessMessage={setSuccessMessage} // Pass down success message state
        setError={setError} // Pass down error state
      />
    </div>
  );
}

export default AuthForm;