// firebase.js
import { getFirestore, collection, getDocs } from 'firebase/firestore';
import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';

const firebaseConfig = {
    apiKey: "AIzaSyDuxHkZiYO9FreEyGv5SZpsG5aHeoowhNk",
    authDomain: "rankpro-df63d.firebaseapp.com",
    databaseURL: "https://rankpro-df63d-default-rtdb.firebaseio.com",
    projectId: "rankpro-df63d",
    storageBucket: "rankpro-df63d.appspot.com",
    messagingSenderId: "1065055932129",
    appId: "1:1065055932129:web:92df03fb406d335e06f1a0",
    measurementId: "G-W0MG5V0F7Y"
  };
  
  // Initialize Firebase
  const app = initializeApp(firebaseConfig);
  const db = getFirestore(app);
  const auth = getAuth(app);

export { auth, db };