import React, { useEffect, useState } from "react";
import { getFirestore, collection, getDocs, doc, setDoc } from "firebase/firestore";
import { Bar, Pie } from "react-chartjs-2";
import "chart.js/auto"; // Import Chart.js
import categories from "./categories-meta"; // Assuming this is your categories-meta.js file
import './DataHub.css'; // Import the CSS file

const DataHub = ({ user }) => {
  const [statistics, setStatistics] = useState({
    totalRankings: 0,
    totalCategories: 0,
  });
  const [chartData, setChartData] = useState({
    labels: [],
    datasets: [],
  });

  const db = getFirestore(); // Initialize Firestore

  // Function to update rankings that are missing metaCategory
  const updateRankingsWithMetaCategory = async (rankings) => {
    for (const ranking of rankings) {
      if (!ranking.metaCategory) {
        let metaCategory = null;
        for (let key in categories) {
          if (categories[key][ranking.category]) {
            metaCategory = key;
            break;
          }
        }

        if (metaCategory) {
          try {
            const rankingRef = doc(db, "rankings", user.email, "userRankings", ranking.category);
            await setDoc(rankingRef, { metaCategory }, { merge: true });
            console.log(`Updated ranking with metaCategory: ${metaCategory}`);
          } catch (error) {
            console.error(`Error updating ranking for ${ranking.category}:`, error);
          }
        }
      }
    }
  };

  useEffect(() => {
    if (!user || !user.email) {
      console.error("User or user email is undefined");
      return;
    }

    const fetchData = async () => {
      try {
        const rankingsSnapshot = await getDocs(
          collection(db, "rankings", user.email, "userRankings")
        );

        if (!rankingsSnapshot) {
          console.error("Rankings snapshot is undefined");
          return;
        }

        const rankings = rankingsSnapshot.docs.map((doc) => doc.data());
        const totalRankings = rankings.length;
        console.log('Rankings:', rankings);

        await updateRankingsWithMetaCategory(rankings);

        const categoryCounts = rankings.reduce((acc, ranking) => {
          const metaCategory = ranking.metaCategory || "Unnamed Meta Category";
          acc[metaCategory] = (acc[metaCategory] || 0) + 1;
          return acc;
        }, {});
        console.log('Meta-Category Counts:', categoryCounts);

        const totalCategories = Object.keys(categoryCounts).length;

        setStatistics({
          totalRankings,
          totalCategories,
        });

        const labels = Object.keys(categoryCounts).length ? Object.keys(categoryCounts) : ['No Categories'];
        const dataValues = Object.values(categoryCounts).length ? Object.values(categoryCounts) : [0];

        // Use a color palette to make the charts visually distinct
        const backgroundColors = [
            '#1f77b4', // Primary Blue
            '#ff7f0e', // Primary Orange
            '#2ca02c', // Primary Green
            '#d62728', // Primary Red
            '#9467bd', // Neutral Purple
            '#8c564b', // Neutral Brown
            '#e377c2', // Light Pink
            '#7f7f7f'  // Neutral Gray
          ];

        setChartData({
          labels: labels,
          datasets: [
            {
              label: "Meta Categories",
              data: dataValues,
              backgroundColor: backgroundColors.slice(0, dataValues.length), // Apply colors dynamically
              borderColor: backgroundColors.slice(0, dataValues.length),
              borderWidth: 1,
            },
          ],
        });
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, [user, db]);

  return (
    <div className="datahub">
      <h2>Summary Statistics</h2>
      <p>Total Rankings: {statistics.totalRankings}</p>
      <p>Total Categories: {statistics.totalCategories}</p>

      <h2>Category Distribution</h2>
      {chartData.labels.length > 0 ? (
        <>
          <div className="chart-container">
            <Bar
              data={chartData}
              options={{
                maintainAspectRatio: false,
                plugins: {
                  legend: {
                    position: 'top', // Move the legend to the right
                  },
                },
              }}
            />
          </div>
          <h2>Category Breakdown</h2>
          <div className="chart-container">
            <Pie
              data={chartData}
              options={{
                maintainAspectRatio: false,
                plugins: {
                  legend: {
                    position: 'top', // Same legend position for Pie chart
                  },
                },
              }}
            />
          </div>
        </>
      ) : (
        <p>No data available to display charts.</p>
      )}
    </div>
  );
};

export default DataHub;