// src/Modal.js
import React from 'react';
import './Modal.css';

const Modal = ({ show, onClose }) => {
  if (!show) {
    return null;
  }

  return (
    <div class="modal-overlay show">
        <div class="modal-content">
            <button class="modal-close-button" onClick={onClose}>&times;</button>
        <p>Welcome to Tier Ranker! </p>
        <p style={{color:"rgba(0,0,0,0.5)"}}>Click on a category to get started.</p>
        </div>
    </div>
  );
};

export default Modal;