import React, { useState } from 'react';
import './suggestion.css'; // Import a separate CSS file for styling

const Suggestion = ({ addCategoryWithOptions }) => {
  const [newCategory, setNewCategory] = useState('');
  const [newOption, setNewOption] = useState('');
  const [categoryOptions, setCategoryOptions] = useState([]);

  const handleInputChange = (e) => {
    setNewCategory(e.target.value);
  };

  const handleOptionChange = (e) => {
    setNewOption(e.target.value);
  };

  const handleAddOption = () => {
    if (newOption.trim()) {
      setCategoryOptions((prevOptions) => [...prevOptions, newOption]);
      setNewOption(''); // Clear input field
    }
  };

  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      handleAddOption();
    }
  };

  const handleAddCategory = () => {
    if (newCategory.trim() && categoryOptions.length > 0) {
      addCategoryWithOptions(newCategory, categoryOptions); // Send the new category and its options to the parent component
      setNewCategory(''); // Reset category field
      setCategoryOptions([]); // Clear options
    }
  };

  return (
    <div className="suggestion-section">
      <h2>Create Your Custom Category</h2>
      <div className="input-section">
        <input
          type="text"
          value={newCategory}
          onChange={handleInputChange}
          placeholder="Enter category name"
          className="input-box"
        />
      </div>
      <div className="input-section">
        <input
          type="text"
          value={newOption}
          onChange={handleOptionChange}
          placeholder="Enter option"
          className="input-box"
          onKeyPress={handleKeyPress}
        />
        <button onClick={handleAddOption} className="add-button">
          Add Option
        </button>
      </div>
      <ul className="option-list">
        {categoryOptions.map((option, index) => (
          <li key={index} className="option-item">
            {option}
          </li>
        ))}
      </ul>
      <button onClick={handleAddCategory} className="add-category-button">
        Save Category with Options
      </button>
    </div>
  );
};

export default Suggestion;